var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    [
      _c("tev-navbar", { attrs: { backTo: "/", title: "Property Setup" } }),
      _c(
        "v-content",
        [
          _c(
            "v-layout",
            { attrs: { row: "", wrap: "", "pt-4": "" } },
            [
              _c(
                "v-flex",
                { staticClass: "mb-5", attrs: { xs12: "", sm12: "" } },
                [
                  _c(
                    "v-container",
                    [
                      _c(
                        "v-layout",
                        { attrs: { row: "", wrap: "" } },
                        [
                          _c(
                            "v-flex",
                            {
                              staticClass: "mb-5",
                              attrs: { xs12: "", sm12: "" },
                            },
                            [
                              _c(
                                "h2",
                                {
                                  staticClass:
                                    "headline font-weight-medium mb-3 black--text",
                                },
                                [
                                  _vm._v(
                                    "IMPORTANT INFORMATION ABOUT TO SETUP A NEW PROPERTY"
                                  ),
                                ]
                              ),
                              _c("p", { staticClass: "mb-4" }, [
                                _vm._v("Hi there,\n                "),
                                _c("br"),
                                _vm._v(
                                  "Before you start, please review the following information to make sure you have all the documentation ready and that you agree with all the terms and conditions. Kindly note the information is autosaved every time the continue button is clicked. We want you to have a great experience during the application process."
                                ),
                              ]),
                              _c(
                                "h2",
                                { staticClass: "title mt-3 mb-3 black--text" },
                                [
                                  _vm._v(
                                    "Important information to be collected"
                                  ),
                                ]
                              ),
                              _c("p", { staticClass: "mb-4" }, [
                                _vm._v(
                                  "Some or all of the following Items may be required, please ensure you to have the information handy in order to complete the application process."
                                ),
                              ]),
                              _vm._l(_vm.requireds, function (item, index) {
                                return _c(
                                  "v-flex",
                                  {
                                    key: index,
                                    staticClass: "mb-2",
                                    attrs: {
                                      "d-flex": "",
                                      row: "",
                                      xs12: "",
                                      "mb-3": "",
                                    },
                                  },
                                  [
                                    _c("CheckMark", {
                                      staticClass: "mr-3",
                                      staticStyle: { flex: "unset!important" },
                                      attrs: { size: 26, delay: index * 300 },
                                    }),
                                    _c("span", [_vm._v(_vm._s(item))]),
                                  ],
                                  1
                                )
                              }),
                              _c("h2", { staticClass: "title mt-5 mb-4" }, [
                                _vm._v(
                                  "Depending on the comunity requirements, additional information may be required."
                                ),
                              ]),
                              _c(
                                "h2",
                                { staticClass: "title mt-5 mb-4 black--text" },
                                [
                                  _vm._v(
                                    "We take your data privacy very seriously."
                                  ),
                                ]
                              ),
                              _c("p", { staticClass: "mb-5" }, [
                                _vm._v(
                                  "Tenant Evaluation LLC, recognizes the responsibility of maintaining our consumer’s personal sensitive information safe and secure. We are PCI/Level 1 Compliant, which is the highest security level that a company can have."
                                ),
                              ]),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "mb-5",
                                  class: {
                                    "blue--text": _vm.isLoading,
                                    "white--text": !_vm.isLoading,
                                  },
                                  attrs: {
                                    color: "primary",
                                    large: "",
                                    block: "",
                                    depressed: "",
                                    loading: _vm.isLoading,
                                    disabled: _vm.isLoading,
                                  },
                                  on: { click: _vm.loadProperty },
                                },
                                [
                                  _vm._v(
                                    "\n                Continue\n              "
                                  ),
                                ]
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }