<template>
  <v-app>
    <tev-navbar backTo="/" title="Property Setup" />
    <v-content>
      <v-layout row wrap pt-4>
        <v-flex xs12 sm12 class="mb-5">
          <v-container>
            <v-layout row wrap>
              <v-flex xs12 sm12 class="mb-5">
                <h2 class="headline font-weight-medium mb-3 black--text">IMPORTANT INFORMATION ABOUT TO SETUP A NEW PROPERTY</h2>
                <p class="mb-4">Hi there,
                  <br>Before you start, please review the following information to make sure you have all the documentation ready and that you agree with all the terms and conditions. Kindly note the information is autosaved every time the continue button is clicked. We want you to have a great experience during the application process.</p>
                <h2 class="title mt-3 mb-3 black--text">Important information to be collected</h2>
                <p class="mb-4">Some or all of the following Items may be required, please ensure you to have the information handy in order to complete the application process.</p>
                <v-flex d-flex row xs12 mb-3 v-for="(item, index) in requireds" :key="index" class="mb-2">
                  <CheckMark :size="26" :delay="index*300" class="mr-3" style="flex: unset!important;" /> <span>{{item}}</span>
                </v-flex>
                <h2 class="title mt-5 mb-4">Depending on the comunity requirements, additional information may be required.</h2>
                <h2 class="title mt-5 mb-4 black--text">We take your data privacy very seriously.</h2>
                <p class="mb-5">Tenant Evaluation LLC, recognizes the responsibility of maintaining our consumer’s personal sensitive information safe and secure. We are PCI/Level 1 Compliant, which is the highest security level that a company can have.</p>
                <v-btn class="mb-5" color="primary" large block depressed @click="loadProperty" :loading="isLoading" v-bind:class="{'blue--text': isLoading, 'white--text': !isLoading}" :disabled="isLoading">
                  Continue
                </v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </v-flex>
      </v-layout>
    </v-content>
  </v-app>
</template>
<script>
import CheckMark from '../components/CheckMark'
import { mapActions } from 'vuex'
import { ACTION_TYPES } from '../libs/constants'

export default {
  components: {
    CheckMark
  },
  data() {
    return {
      propertyId: null,
      isLoading: false,
      requireds: [
        'Company FEI/EIN number',
        'Business information and entity type',
        'Copy of articles of incorporation',
        'Copy of annual report',
        'Credit reporting compliance officer information',
        'List of documents required by the association'
      ]
    }
  },
  methods: {
    ...mapActions([
      ACTION_TYPES.UPDATE_APP_PROPERTIES,
      ACTION_TYPES.RESET_APP_PROPERTIES
    ]),
    loadProperty() {
      const _this = this
      this.isLoading = true
      this.$http.get('/manager/properties/pending/'+this.propertyId)
        .then(response => {
          _this[ACTION_TYPES.UPDATE_APP_PROPERTIES](response.data)
          _this.$router.push('/form/'+_this.propertyId)
        })
        .catch(e => {
          // eslint-disable-next-line
          console.error(e)
          _this.$router.push('/properties')
        })
        .finally(() => {
          _this.isLoading = false
        })
    }
  },
  mounted() {
    this[ACTION_TYPES.RESET_APP_PROPERTIES]()
    this.propertyId = this.$route.params.propertyId;
  }
}
</script>
<style>
</style>
